import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactSection from "../components/Home/ContactSection"
import HorizontalCard from "../components/Home/MapSection/HorizontalCard"

const CitiesPage = () => {
  const cities = [
    "Калуга",
    "Тула",
    "Смоленск",
    "Брянск",
    "Орёл",
    "Воронеж",
    "Белгород",
    "Курск",
    "Тверь",
    "Саратов",
    "Липецк",
    "Иваново",
    "Волгоград",
    "Владимир",
    "Нижний Новгород",
  ]
  return (
    <Layout>
      {
        // eslint-disable-next-line
        <SEO
          title="Пункты приема катализаторов и контакты"
          description="Все пункты приема катализаторов и сажевых фильтров по всей Росиии и СНГ"
        />
      }
      <section
        id="cities"
        aria-labelledby="cities-heading"
        className="mx-auto max-w-full py-6 px-0 sm:py-32 md:px-12 lg:max-w-7xl "
      >
        <h1
          id="cities-heading"
          className="pt-20 text-center text-xl font-bold md:pt-0 md:text-5xl"
        >
          Пункты приема катализаторов и контакты
        </h1>

        <div className="mx-auto flex flex-col px-1 pt-2 sm:pt-12 ">
          {cities.map((city, index) => (
            <HorizontalCard key={`city-${index}`} city={city} />
          ))}
        </div>
      </section>

      <ContactSection />
    </Layout>
  )
}

export default CitiesPage
